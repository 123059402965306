<template>
  <div class="raidersRight_page">
    <img class="bg"
         src="../../sprite/community.jpg"
         alt=""
         ref="bgRef">
    <img class="qr_code"
         src="../../sprite/customer_service_QRcode.jpg"
         alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {

  }
}
</script>
<style lang="less" scoped>
.raidersRight_page {
  min-height: 100vh;
  background: #FFD4EE;
  // background: linear-gradient(
  //   94deg,
  //   #ff7a12 0%,
  //   #ff7a12 30%,
  //   #ff8216 50%,
  //   #ff9e22 75%,
  //   #ffa123 100%
  // );

  .bg {
    width: 100%;
    height: 100%;
    vertical-align: middle;
  }
  .qr_code {
    position: absolute;
    top: 11.9rem;
    left: 0.6rem;
    width: 2.84rem;
    height: 2.84rem;
    border-radius: 0.50rem;
  }
}
</style>